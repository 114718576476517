import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const Legal = () => (
  <Layout>
    <Seo title='Aviso legal' meta={{ name: 'robots', content: 'noindex' }} />
    <div className='static-page'>
      <h1>Aviso legal</h1>
      <h2>I. OBJETO</h2>
      <p>Este sitio web pertenece y es gestionado por GEEKS C.B. con NIF E99511750, en lo sucesivo denominado “Titular”.</p>
      <h2>II. PROPIEDAD INTELECTUAL</h2>
      <p>Los contenidos de esta web, incluyendo, entre otros, imágenes, logotipos, gráficos, animaciones, textos o aplicaciones informáticas, cualesquiera que sea su formato, lenguaje de programación y forma de representación, así como el nombre de dominio www.educalista.es son propiedad del Titular, o bien ha adquirido los correspondientes derechos de sus propietarios, y están protegidos por las leyes y tratados internacionales en materia de propiedad intelectual y, en su caso, industrial, así como por la normativa reguladora de los nombres de dominio. Se permite la utilización o reproducción, total o parcial, sin ánimo de lucro, de sus contenidos siempre que se haga constar la fuente y se incluya un hipervínculo a esta web, y en los casos permitidos en los artículos 31 a 34 de la Ley de Propiedad Intelectual (Real Decreto Legislativo 1/1996, de 12 de abril). Queda prohibido el uso de marcos (frames) o cualesquiera otros mecanismos destinados a ocultar el origen o fuente de los contenidos, cualquier uso, transformación o explotación de los mismos con finalidades comerciales, promocionales o contrarias a la ley, moral u orden público, que pudiera lesionar los intereses o perjudicar la imagen del Titular o de terceros legítimos, que constituyan competencia desleal o, en general, en contra de lo dispuesto en este documento.</p>
      <h2>III. USO DE COOKIES</h2>
      <p>Este sitio web utiliza cookies propias para gestionar la sesión de usuarios registrados así como cookies de Google Analytics para analizar las visitas y actividad de los usuarios. Cualquier acción que no sea el bloqueo de las mismas a través de la herramienta que aparece en la zona inferior de la web implica el consentimiento para su uso. La aplicación anterior grabará asimismo una cookie para mantener sus preferencias. El bloqueo o eliminación posterior de las cookies de Google Analytics, a través de los correspondientes parámetros de su navegador, no afectará al acceso a estas páginas. La responsabilidad relativa al uso de datos del usuario por medio de las cookies de Google Analytics corresponde exclusivamente a Google, Inc., una compañía de Delaware cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos. La información que generan dichas cookies acerca de su uso del website (incluyendo su dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos. Google usará estos datos, páginas y búsquedas referentes, datos de localización, y otros hábitos de navegación, para recopilar información sobre el uso del sitio web, recopilando informes de la actividad y prestando otros servicios relacionados el website y el uso de Internet. Google establece en sus condiciones que podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google.</p>
      <h2>IV. LIMITACIÓN DE RESPONSABILIDAD</h2>
      <p>Los contenidos de este sitio web se ofrecen con una finalidad informativa y divulgativa. El Titular se exime de cualquier responsabilidad, directa o indirecta, que pudiera derivarse del uso o aplicación de la información de esta web fuera de sus propósitos. Los enlaces (hipervínculos) o contenidos de terceros que aparecen en esta web no implican la aceptación de dichos contenidos, ni la asociación del Titular con los responsables de los mismos, por lo que rechaza toda responsabilidad en relación con los mismos, así como por los daños que pudieran causarse por cualquier motivo en su sistema informático (equipo y aplicaciones), documentos o ficheros. El Titular sólo podrá ser responsable por dichos contenidos conforme a lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, en caso de que, habiendo tenido conocimiento efectivo de su ilicitud o de que lesiona los bienes o intereses de un tercero, no elimine o bloquee los correspondientes contenidos. El Titular no garantiza la fiabilidad, disponibilidad o continuidad de este sitio web ni de sus contenidos por motivos técnicos, de seguridad, control o mantenimiento del servicio, por fallos debidos al servidor que aloja los contenidos o de otros intermediarios o proveedores, por ataques contra el sistema informático, ni por cualesquiera otros motivos que se deriven de causas que escapen a su control, por lo que rechaza cualquier responsabilidad, directa o indirecta, por los mismos.</p>
      <h2>V. PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL</h2>
      <p>Al navegar por estas páginas, de forma automática Ud. facilita al servidor de la web información relativa a su dirección IP (identificador único para la transmisión de paquetes entre ordenadores conectados a Internet), fecha y hora de acceso, el hipervínculo que le ha reenviado a éstas, su sistema operativo y el navegador utilizado. Sin perjuicio de que la Agencia Española de Protección de Datos (en adelante, AGPD) considera la IP como dato de carácter personal, el Titular no puede obtener por si sólo, ni tiene intención de hacerlo a menos que se le cause algún perjuicio, información alguna acerca del titular de la conexión a Internet a la que corresponde. Estos datos serán guardados y utilizados únicamente para el control y realización de estadísticas de acceso y visitas a la web, y en ningún caso serán comunicados o cedidos a terceros.</p>
      <p>En caso de que Ud. establezca algún tipo de comunicación con el Titular, ya sea por teléfono, fax, correo ordinario o electrónico, sus datos serán guardados e incluidos en un fichero propiedad del Titular, con la finalidad de responder a su consulta, sugerencia o queja, informar o prestar el servicio solicitado. Fuera de los casos legalmente establecidos, sus datos no serán comunicados ni cedidos a terceros sin su consentimiento expreso. Dicho fichero se encuentra convenientemente inscrito en el Registro General de la AEPD y sobre él se aplican las medidas técnicas, legales y organizativas establecidas en la normativa sobre protección de datos de carácter personal para evitar cualquier acceso, alteración, pérdida o tratamiento no autorizados.</p>
      <p>Tiene Ud. derecho a acceder a dicho fichero para consultar sus datos, rectificarlos o cancelarlos, así como a oponerse a todo tratamiento respecto de los mismos, con las excepciones y límites establecidos en la legislación aplicable. En caso de que desee ejercer dichos derechos, dirija al Titular un escrito indicando su nombre, apellidos y dirección completa y adjunte una fotocopia de su DNI o pasaporte. Deberá indicarse en el sobre el motivo por el que se dirige a nosotros y enviarlo a la siguiente dirección: Pablo Alayeto García, Paseo Gran Vía 33C Bajo 3, 50.006 Zaragoza. En el sitio web de la AGPD tiene a su disposición modelos de formularios y más información al respecto.</p>
      <h2>VI. MODIFICACIONES Y ACTUALIZACIONES</h2>
      <p>El Titular se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y actualizaciones de la información contenida en la web, de la configuración, disponibilidad y presentación de ésta.</p>
    </div>
  </Layout>
)

export default Legal
